.icon-img{
    width: 40px;
}
/* .css-hyum1k-MuiToolbar-root {
    min-height: 30px !important;
} */
.appbar-h{
    color: var(--main-color);
    font-family: "Unbounded", sans-serif;
}
.all-clinics-main{
    width: 80%;
    display: flex;
    justify-content: end;
    align-items: center;
}
.search-bar {
    border: 2px solid var(--main-color);
    border-radius: 30px;
    background-color: white;
    color: black;
   
}

.loader__balls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0px;
    height: 80vh;
    width: 100%;
  }
  .loader__balls__group {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    height: 100px;
    width: 40px;
  }
  .ball {
    height: 20px;
    width: 20px;
    border-radius: 15px;
    position: absolute;
    transform-origin: bottom;
  }
  
  /* ANIMATION BALL 1*/
  .loader__balls__group :nth-child(1) {
    background-color: #99e2d0;
    animation-name: jumpinBallAnimation1;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
  }
  @keyframes jumpinBallAnimation1 {
    0% {
      transform: translateY(0) scale(1, 1);
    }
    10% {
      transform: translateY(0) scale(1.3, 0.8);
    }
    11% {
      transform: translateY(0) scale(0.7, 1.2);
      animation-timing-function: cubic-bezier(0, 0, 0.5, 1);
    }
    39% {
      transform: translateY(-75px) scale(1);
      animation-timing-function: cubic-bezier(0, 0, 0.5, 1);
    }
    40% {
      transform: translateY(-75px) scale(1);
    }
    41% {
      transform: translateY(-75px) scale(1);
      animation-timing-function: cubic-bezier(1, 0, 1, 0);
    }
    69% {
      transform: translateY(0px) scale(1, 1);
      animation-timing-function: cubic-bezier(1, 0, 1, 0);
    }
    70% {
      transform: translateY(0) scale(1.5, 0.4);
    }
    80% {
      transform: translateY(0) scale(0.8, 1.2);
    }
    90% {
      transform: translateY(0) scale(1.1, 0.8);
    }
    100% {
      transform: translateY(0) scale(1, 1);
    }
  }
  
  /* ANIMATION BALL 2*/
  .loader__balls__group :nth-child(2) {
    background-color: #ff79da;
    animation-name: jumpinBallAnimation2;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
  }
  @keyframes jumpinBallAnimation2 {
    0% {
      transform: translateY(0) scale(1, 1);
    }
    10% {
      transform: translateY(0) scale(1.3, 0.8);
    }
    11% {
      transform: translateY(0) scale(0.7, 1.2);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    39% {
      transform: translateY(-75px) scale(1);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    40% {
      transform: translateY(-75px) scale(1);
    }
    41% {
      transform: translateY(-75px) scale(1);
      animation-timing-function: cubic-bezier(1, 0, 1, 0.5);
    }
    69% {
      transform: translateY(0px) scale(1, 1);
      animation-timing-function: cubic-bezier(1, 0, 1, 0.5);
    }
    70% {
      transform: translateY(0) scale(1.5, 0.4);
    }
    80% {
      transform: translateY(0) scale(0.8, 1.2);
    }
    90% {
      transform: translateY(0) scale(1.1, 0.8);
    }
    100% {
      transform: translateY(0) scale(1, 1);
    }
  }
  
  /* ANIMATION BALL 3*/
  .loader__balls__group :nth-child(3) {
    background-color: #9356dc;
    animation-name: jumpinBallAnimation3;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
  }
  @keyframes jumpinBallAnimation3 {
    0% {
      transform: translateY(0) scale(1, 1);
    }
    10% {
      transform: translateY(0) scale(1.3, 0.8);
    }
    11% {
      transform: translateY(0) scale(0.7, 1.2);
      animation-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
    39% {
      transform: translateY(-75px) scale(1);
      animation-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
    40% {
      transform: translateY(-75px) scale(1);
    }
    41% {
      transform: translateY(-75px) scale(1);
      animation-timing-function: cubic-bezier(1, 0, 1, 1);
    }
    69% {
      transform: translateY(0px) scale(1, 1);
      animation-timing-function: cubic-bezier(1, 0, 1, 1);
    }
    70% {
      transform: translateY(0) scale(1.5, 0.4);
    }
    80% {
      transform: translateY(0) scale(0.8, 1.2);
    }
    90% {
      transform: translateY(0) scale(1.1, 0.8);
    }
    100% {
      transform: translateY(0) scale(1, 1);
    }
  }
  .loader__balls__group .item1 {
    animation-delay: 0ms;
  }
  .loader__balls__group .item2 {
    animation-delay: 100ms;
  }
  .loader__balls__group .item3 {
    animation-delay: 200ms;
  }
  .user-search{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .user-search button{
    height: 50px;
  }


